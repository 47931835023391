import classNames from 'classnames';
import type {PropsWithChildren} from 'react';

import * as styles from './typography.module.scss';

type TextPropsType = PropsWithChildren<{
    type?: 'secondary';
    strong?: boolean;
    className?: string;
    size?: 'small' | 'medium' | 'large';
    ellipsis?: boolean;
    block?: boolean;
}>;

function Text(props: TextPropsType): React.JSX.Element {
    const {strong, block, type, children, className, size = 'medium', ellipsis} = props;

    const classNameString = classNames(
        {
            [styles.TypographyText_secondary]: type === 'secondary',
            [styles.TypographyText_strong]: Boolean(strong),
            [styles.TypographyText_small]: size === 'small',
            [styles.TypographyText_medium]: size === 'medium',
            [styles.TypographyText_large]: size === 'large',
            [styles.TypographyTitle_ellipsis]: ellipsis,
        },
        styles.TypographyText,
        className,
    );

    if (block) {
        return <p className={classNameString}>{children}</p>;
    }

    return <span className={classNameString}>{children}</span>;
}

function Title(
    props: PropsWithChildren<{className?: string; ellipsis?: boolean; size?: 'medium' | 'large' | 'small'}>,
): React.JSX.Element {
    const {className, children, ellipsis, size = 'medium'} = props;

    const classNameString = classNames(
        styles.TypographyTitle,
        {
            [styles.TypographyTitle_small]: size === 'small',
            [styles.TypographyTitle_medium]: size === 'medium',
            [styles.TypographyTitle_large]: size === 'large',
            [styles.TypographyTitle_ellipsis]: ellipsis,
        },
        className,
    );

    return <h3 className={classNameString}>{children}</h3>;
}

export const Typography = {
    Text,
    Title,
};

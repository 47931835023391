'use client';

import Image, {type StaticImageData} from 'next/image';
import {useTranslations} from 'next-intl';

import {Typography} from '@/components/typography/typography';

import notFoundImage from './not-found.svg';
import styles from './not-found.module.scss';

export function NotFound(): React.JSX.Element {
    const t = useTranslations('NotFound');

    return (
        <main className={styles.NotFound_main}>
            <Image alt="" src={notFoundImage as StaticImageData} />

            <Typography.Title className={styles.NotFound_title}>{t('title')}</Typography.Title>

            <Typography.Text className={styles.NotFound_description} type="secondary">
                {t('description')}
            </Typography.Text>
        </main>
    );
}
